/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Beatriz & João"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-kh6hde css-1veinxa js-anim  --anim7 --anim-s5 --full" style={{"marginBottom":0,"paddingBottom":0}} anim={"7"} name={"intro"} animS={"5"} border={null} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/7bebd3050a144d9e9963e2ae20131e25_s=3000x_.jpg);
    }
  
background-position: 48% 34%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" style={{"maxWidth":1080,"marginBottom":42,"paddingBottom":0}} anim={"6"} animS={"5"}>
              
              <Title className="title-box title-box--shadow4 title-box--center fs--62 w--300 lh--12" style={{"marginTop":0,"paddingTop":261,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: var(--color-blend--15);\">Beatriz &amp; João Pedro</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--shadow4 fs--30" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: white\">20-07-2024</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--50" name={"information"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center fs--72 w--300" content={"Missa"}>
              </Title>

              <Text className="text-box fs--30" content={"15:30 H<br>"}>
              </Text>

              <Text className="text-box fs--30" content={"Igreja de S. Francisco"}>
              </Text>

              <Text className="text-box fs--30" content={"<a href=\"https://www.google.com/maps/place/Igreja+de+Nossa+Senhora+da+Concei%C3%A7%C3%A3o/@40.2826424,-7.5043088,20z/data=!4m15!1m8!3m7!1s0xd3d239636556bab:0x2d6eba2176d7be75!2sLargo+da+Infantaria+21+3,+Covilh%C3%A3!3b1!8m2!3d40.282689!4d-7.5042934!16s%2Fg%2F11gfmkbcl7!3m5!1s0xd3d239637b72cf1:0xa2577fe7b445fdd2!8m2!3d40.2827142!4d-7.5042915!16s%2Fg%2F11cnd4vx9m?entry=ttu\" target=\"_blank\">Largo da Infantaria 21 3,&nbsp;6200-020 Covilhã&nbsp;</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image className="--shape2" src={"https://cdn.swbpg.com/t/36889/ed029d20e964418e900ed034d77e7c15_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36889/ed029d20e964418e900ed034d77e7c15_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36889/ed029d20e964418e900ed034d77e7c15_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36889/ed029d20e964418e900ed034d77e7c15_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36889/ed029d20e964418e900ed034d77e7c15_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--25" name={"information-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image src={"https://cdn.swbpg.com/t/36889/3fe6d642cc7549d2aa8287130d890778_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36889/3fe6d642cc7549d2aa8287130d890778_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36889/3fe6d642cc7549d2aa8287130d890778_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36889/3fe6d642cc7549d2aa8287130d890778_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":648}}>
              
              <Title className="title-box title-box--style8 title-box--center fs--72 w--300" content={"Festa"}>
              </Title>

              <Text className="text-box text-box--left fs--30" content={"<span style=\"color: var(--white);\">Cocktail e Jantar</span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--26" content={"<span style=\"color: var(--color-blend--50);\">17:30 H</span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--26" content={"<span style=\"color: var(--color-blend--50);\">Quinta da Amoreira</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--26" content={"<a href=\"https://www.google.com/maps/place/Tv.+Primeira+da+Rua+das+Amoreiras+4+6200+006,+6200-006+Covilh%C3%A3/@40.2978614,-7.4666615,18z/data=!4m5!3m4!1s0xd3d223be78917b1:0x486e025f53a05e0d!8m2!3d40.297847!4d-7.46652?entry=ttu\" target=\"_blank\" style=\"color: var(--color-blend--50);\">Tv. Primeira da Rua das Amoreiras 4, 6200-006 Covilhã</a>"}>
              </Text>

              <Text className="text-box text-box--left fs--30" content={"<span style=\"color: var(--white);\">Dress Code</span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--26" content={"<span style=\"color: var(--color-blend--50);\">Formal</span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--26" content={"<span style=\"color: var(--color-blend--50);\">O jantar será no exterior da quinta</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--30" content={"<span style=\"color: var(--white);\">Uber e Táxis</span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--26" content={"<span style=\"color: var(--color-blend--50);\">Uber disponível na Covilhã\n<br>Contacto táxi: Sr. Pedro - 928 113 116</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"var(--color-blend--05)"}} name={"information-4"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--center w--300" content={"<span style=\"color: var(--color-custom-1--95);\">Querida Família e Amigos</span><br>"}>
              </Title>

              <Text className="text-box text-box--justify fs--24" content={"Já lá vão 14 anos de muito Amor, Amizade e Partilha… e agora, que estamos tão perto do dia em que vai começar a nossa Família,&nbsp; queremos partilhar a felicidade deste momento tão importante para nós com os que mais amamos. Assim, sintam-se convidados a fazer parte deste dia tão especial e a torná-lo ainda mais feliz com a vossa presença!<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18" style={{"marginTop":87}} href={"/information"} content={"<span style=\"color: var(--color-custom-1--95);\">“O Amor é a única coisa que cresce à medida que se reparte…”</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/36889/d3865c52900a4e1ebd9f3dee2e350e6c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36889/d3865c52900a4e1ebd9f3dee2e350e6c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36889/d3865c52900a4e1ebd9f3dee2e350e6c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36889/d3865c52900a4e1ebd9f3dee2e350e6c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36889/d3865c52900a4e1ebd9f3dee2e350e6c_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-rsb43n css-2td8d5 --full pb--60 pt--60" name={"2641bs96sns"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36889/860e4e9a1f1c472aa2ba5463597b8393_s=3000x_.jpg);
    }
  
background-position: 50% 76%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"marginTop":117,"paddingTop":0,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--22 title-box--invert" style={{"maxWidth":1376,"paddingTop":0}} content={"<span style=\"color: var(--white);\">Contamos com a vossa presença!</span><br>"}>
              </Title>

              <Text className="text-box fs--16 text-box--invert" style={{"maxWidth":650,"marginTop":0}} content={"<span style=\"color: var(--white)\">20-07-2024</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":62,"paddingBottom":63}} name={"1n1xxx2jwmg"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Contactos"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left fs--26" style={{"maxWidth":334}} content={"<span style=\"color: var(--color-blend--15);\">Beatriz:</span> 963 771 090<br><span style=\"color: var(--color-blend--15);\">João Pedro:</span> 967 463 215<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":326}}>
              
              <Image style={{"maxWidth":145}} alt={""} src={"https://cdn.swbpg.com/t/36889/4a5c66f0c7dd40ab8f485418baa5bed8_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36889/4a5c66f0c7dd40ab8f485418baa5bed8_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}